import { reactive } from "vue";
import ticket from "./methods/ticket";
import userVerification from "./methods/userVerification";
import chat from "./methods/chat";
const state = reactive({
	needVerifyCode: false,
	auth: false,
	isLoading: false,
	session: null,
	profile: {},
	listenIncomingTicket: false,
	totalNewTicket: 0,
	//mage current chats
	chats: {
		isListenActive: false,
		chatLength: null,
		doctor: {},
		conversation: {},
	},
	ticket: {
		request: null,
		start: null,
		complete: null,
		ticketInfo: null,
		buttonName: null,
		isOpenPopup: false,
	},
	uploadImg: {
		// 	UserId: sessionStorage.getItem("") || "",
		// 	DoctorToken: sessionStorage.getItem("") || "",
		// 	TicketNo: sessionStorage.getItem("") || "",
		ChatPhotoName: null,
		ChatPhotoExtension: null, //ok
		Message: null,
		ChatPhoto: null, //ok
		isOpenPopup: false, //ok
		imgPreview: null,
	},
	endChat: {
		// TicketFollowUpId: null,
		// TicketDoctorNote: null,
		isOpenPopup: false,
	},
});

export default {
	state,
	ticket,
	userVerification,
	chat,
};
