import firebase from "firebase/app";
import "firebase/firestore";
import envConfig from "./env.config";

const prodConfig = {
	apiKey: "AIzaSyB1f1zIoDLc0usz1UFWLZr5KGLX9r5Cvf8",
	authDomain: "relidoc.firebaseapp.com",
	projectId: "relidoc",
	storageBucket: "relidoc.appspot.com",
	messagingSenderId: "1016439692533",
	appId: "1:1016439692533:web:bb808df7c7a7f3218c79bc",
}

const devConfig = {
	apiKey: 'AIzaSyBebFjnoqUvU4Z7pJMpC00odtOErX62FIY',
	authDomain: 'develop-relidoc.firebaseapp.com',
	projectId: 'develop-relidoc',
	storageBucket: 'develop-relidoc.appspot.com',
	messagingSenderId: '649013176798',
	appId: '1:649013176798:web:f2a1a5ecede0435620954f',
	measurementId: 'G-QXW22SFH7D',
}

let config;

if(envConfig.isProd && envConfig.appMode === 'prod') {
	config = prodConfig;
} else {
	config = devConfig;
}

firebase.initializeApp(config);
const db = firebase.firestore();
db.settings({ timestampsInSnapshots: true });
export default db;
