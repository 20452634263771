import db from "@/fb";
import store from "../index";
//import axiosAri from "./utils/axiosAri";
import axiosAap from "./utils/axiosAap";

export async function addMessage(msg, ticketID, uploadPhoto) {
	//console.log(msg, ticketID, uploadPhoto, localStorage.getItem("Token"));
	axiosAap({
		method: "post",
		url: "/doctor/chats",
		headers: { Authorization: `Bearer ${localStorage.getItem("TokenAap")}` },
		data: {
			ticketId: ticketID,
			userId: localStorage.getItem("DoctorId"),
			message: msg,
			uploadPhotoUrl: uploadPhoto,
		},
	});
}

export async function addPhoto(ticketID) {
	return await axiosAap({
		method: "post",
		url: "/doctor/chats-upload-photo",
		headers: {
			Authorization: `Bearer ${localStorage.getItem("TokenAap")}`,
		},
		data: {
			UserId: localStorage.getItem("DoctorId"),
			DoctorToken: localStorage.getItem("DoctorToken"),
			TicketNo: ticketID,
			ChatPhotoName: store.state.uploadImg.ChatPhotoName,
			ChatPhotoExtension: store.state.uploadImg.ChatPhotoExtension,
			Message: store.state.uploadImg.Message || "",
			ChatPhoto: store.state.uploadImg.ChatPhoto,
		},
	});
}

// get active conversation from firestore
export async function getConversations(id) {
	const docRef = await db.collection("tickets").doc(id);
	const doc = await docRef.get();
	if (!doc.exists) {
		console.log("No such document!");
	} else {
		const snapshots = doc.data();
		console.log(
			"mengambil data percakapan baru dari server dan set ke local, length",
			Object.keys(snapshots.conversation).length
		);

		//sort conversation by key
		const ordered = Object.keys(snapshots.conversation)
			.sort()
			.reduce((obj, key) => {
				obj[key] = snapshots.conversation[key];
				return obj;
			}, {});
		//store to local state
		//console.log("data snaphost", snapshots);
		//data ini harusnya yang direturn
		store.state.chats.conversation = ordered;
		//generate welcome message
		const isGenerate = Object.entries(snapshots.conversation).find(function(v) {
			if (v[1].userId == localStorage.getItem("DoctorId")) {
				return true;
			}
		});
		//console.log("log generate", isGenerate);
		if (isGenerate == null) {
			addMessage(
				"Hallo dengan saya " +
					localStorage.getItem("DoctorName") +
					" ada yang bisa dibantu.",
				id,
				""
			);
		}

		//store.state.chats.doctor = snapshots.participant.doctor;
		//updating local chatLength
		store.state.chats.chatLength = Object.keys(snapshots.conversation).length;
		store.state.profile.userId = localStorage.getItem("DoctorId");

		//return data
		//return ordered;
	}
}

//Check realtime data on firestore
export function checkRealTime(id) {
	//console.log("checkRealTime triggered with ID", id);
	let doc = db.collection("tickets").doc(id);
	//checking isListenActive to preventing double re init Listen
	if (store.state.chats.isListenActive == false) {
		//starting initial listener to get realtime update data from firestore
		doc.onSnapshot(
			(docSnapshot) => {
				//set initial isListenActive to TRUE to preventing double listen on next action
				store.state.chats.isListenActive = true;

				//getobject data
				let snapshots = docSnapshot.data();
				console.log("snapshots", snapshots);
				if (snapshots != null) {
					//sorting and set conversation by key
					let ordered = Object.keys(snapshots.conversation)
						.sort()
						.reduce((obj, key) => {
							obj[key] = snapshots.conversation[key];
							return obj;
						}, {});
					//store to local state
					store.state.chats.conversation = ordered;
					store.state.chats.doctor = snapshots.participant
						? snapshots.participant.doctor
						: null;
					//updating local chatLength
					store.state.chats.chatLength = Object.keys(
						snapshots.conversation
					).length;

					//***Additional Validation */
					//check current length of chats
					let lengthChatServer = Object.keys(docSnapshot.data().conversation)
						.length;
					let lengthChatLocal = store.state.chats.chatLength;
					//only update get conversation if length chat on firebase != lengthChatLocal
					if (lengthChatLocal != null && lengthChatLocal != lengthChatServer) {
						console.log(
							lengthChatServer,
							lengthChatLocal,
							"ada data chat baru, then getConversations"
						);
						getConversations(id);
					}
				}
			}
			// (err) => {
			// 	console.log(`Encountered error: ${err}`);
			// }
		);
	}
}

export async function updateStatusOnChatRoom(status, ticketID) {
	//console.log("update on chat room");
	//console.log(status, ticketID);
	return await axiosAap({
		method: "post",
		url: "/doctor/update-status-on-chat-room",
		headers: {
			Authorization: `Bearer ${localStorage.getItem("TokenAap")}`,
		},
		data: {
			UserId: localStorage.getItem("DoctorId"),
			DoctorToken: localStorage.getItem("DoctorToken"),
			TicketNo: ticketID,
			DoctorOnChatRoom: status,
		},
	});
}

export function clearStateChat() {
	store.state.chats.isListenActive = false;
	store.state.chats.chatLength = null;
	store.state.chats.doctor = {};
	store.state.chats.conversation = {};
}
export default {
	getConversations,
	clearStateChat,
	addMessage,
	addPhoto,
	checkRealTime,
	updateStatusOnChatRoom,
};
