import { createRouter, createWebHistory } from "@ionic/vue-router";
//import store from "@/store";
//import { convertTZ } from "../store/methods/utils/utils";
const routes = [
	{
		path: "",
		redirect: "/conversation",
	},
	{
		path: "/login",
		component: () => import("@/views/Login.vue"),
		name: "Login",
	},
	{
		path: "/verify-code",
		component: () => import("@/views/VerifyCode.vue"),
		name: "VerifyCode",
	},
	{
		path: "/error-page",
		component: () => import("@/views/ErrorPage.vue"),
		name: "ErrorPage",
	},
	{
		path: "/conversation",
		component: () => import("@/views/conversation/Conversation.vue"),
		name: "Conversation",
	},
	{
		path: "/end-chat/:id",
		component: () => import("@/components/DoctorEndChat.vue"),
		name: "EndChat",
		props: true,
	},
	{
		path: "/view-image",
		component: () => import("@/components/ViewImage.vue"),
		name: "ViewImage",
		props: true,
	},
	{
		path: "/ticket-information/:id",
		component: () => import("@/views/conversation/TicketInformation.vue"),
		name: "TicketInformationOnRequest",
	},
	{
		path: "/ticket-information-detail/:id",
		component: () =>
			import("@/views/ticket-complete/TicketInformationDetail.vue"),
		name: "TicketCompleteDetail",
	},
	{
		path: "/active-chat/:id",
		component: () => import("@/views/conversation/ActiveChat.vue"),
		name: "ActiveChat",
		props: true,
	},
	{
		path: "/chat-history/:id",
		component: () => import("@/views/ticket-complete/ChatHistory.vue"),
		name: "ChatHistory",
		props: true,
	},
	{
		path: "/ticket-onprocess",
		component: () => import("@/views/ticket-onprocess/TicketOnProcess.vue"),
		name: "TicketOnProcess",
	},
	{
		path: "/ticket-onprocess-detail/:id",
		component: () =>
			import("@/views/ticket-onprocess/TicketOnProcessDetail.vue"),
		name: "TicketOnProcessDetail",
	},
	{
		path: "/ticket-complete",
		component: () => import("@/views/ticket-complete/TicketComplete.vue"),
		name: "TicketComplete",
	},
	{
		path: "/profile",
		component: () => import("@/views/Profile.vue"),
		name: "Profile",
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

//create middleware to check auth on every url that accesed by user
router.beforeEach((to, from, next) => {
	// redirect to login page if not logged in and trying to access a restricted page
	const publicPages = ["/login", "/verify-code", "/error-page"];
	// localStorage.setItem("Token", "token-no");
	const authRequired = !publicPages.includes(to.path); //check any url that need auth
	const loggedIn = localStorage.getItem("DoctorToken");
	if (loggedIn != null && (to.path == "/login" || to.path == "/verify-code")) {
		return next("/conversation");
	}

	//if NOT login and user try to force access secret page then validate it
	if (authRequired && !loggedIn) {
		if (to.path == "/verify-code") {
			return next("/verify-code");
		} else if (to.path == "/error-page") {
			return next("/error-page");
		} else {
			//set auto logout when access login page
			//store.userVerification.logout();
			return next("/login");
		}
	}

	//user user login and access secret page
	if (authRequired && loggedIn) {
		if (
			to.path.includes("/ticket-onprocess-detail") &&
			from.path == "/ticket-complete"
		) {
			return next("/ticket-onprocess");
		}
		if (
			to.path.includes("/ticket-onprocess-detail") &&
			from.path == "/conversation"
		) {
			return next("/ticket-onprocess");
		}
		if (
			to.path.includes("/ticket-onprocess-detail") &&
			from.path.includes("/active-chat")
		) {
			return next("/ticket-onprocess");
		}

		//versi web base ini di comment
		// if (
		// 	to.path.includes("/ticket-information-detail") &&
		// 	from.path == "/ticket-complete"
		// ) {
		// 	return next("/ticket-onprocess");
		// }
		// if (
		// 	to.path.includes("/ticket-information-detail") &&
		// 	from.path == "/conversation"
		// ) {
		// 	return next("/ticket-onprocess");
		// }
		// if (
		// 	to.path.includes("/ticket-information-detail") &&
		// 	from.path.includes("/active-chat")
		// ) {
		// 	return next("/ticket-onprocess");
		// }
	}

	//Checking is Token Expired
	// const doctorTokenExp = parseInt(localStorage.getItem("DoctorTokenExp"));
	// if (doctorTokenExp != null) {
	// 	const time = doctorTokenExp - new Date().getTime();
	// 	if (time < 0) {
	// 		store.userVerification.logout();
	// 		console.log("token expired");
	// 	}
	// }
	//Checking login Date
	// const doctorLoginDate = parseInt(localStorage.getItem("DoctorLoginDate"));
	// if (doctorLoginDate != null) {
	// 	const date = convertTZ(new Date(), "Asia/Jakarta").getDate();
	// 	if (parseInt(date) != doctorLoginDate) {
	// 		store.userVerification.logout();
	// 		console.log("token expired..");
	// 	}
	// }
	next();
});

export default router;
