const env = {
    nodeEnv: process.env.NODE_ENV,
    baseUrl: process.env.BASE_URL,
    prod: 'production',
    dev: 'development',  
    get isProd() {
        return process.env.NODE_ENV == this.prod;
    },
    get apiARI() {
        return (this.isProd ? process.env.VUE_APP_API_ARI_PROD : process.env.VUE_APP_API_ARI_DEV)
    },
    get apiNODE() {
        return (this.isProd ? process.env.VUE_APP_API_NODE_PROD : process.env.VUE_APP_API_NODE_DEV)
    },
    appMode: process.env.VUE_APP_MODE    
}

export default {
    ...env,
}