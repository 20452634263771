import { catchAsyncPromise } from "./errorCatch";

export function formatDate(v) {
	const monthNames = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];
	const i = new Date(v);
	const format = `${i.getDate()} ${
		monthNames[i.getMonth()]
	} ${i.getFullYear()} ${i.getHours() < 10 ? "0" : ""}${i.getHours()}:${
		i.getMinutes() < 10 ? "0" : ""
	}${i.getMinutes()}`;
	return format;
}

export function formatDob(v) {
	const monthNames = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];
	const i = new Date(v);
	const format = `${i.getDate()} ${
		monthNames[i.getMonth()]
	} ${i.getFullYear()}`;
	return format;
}

export function getImageDimensions(image) {
	return new Promise((resolve) => {
		image.onload = function() {
			const width = this.width;
			const height = this.height;
			resolve({ height, width });
		};
	});
}

export function compressImage(
	image,
	scale,
	initalWidth,
	initalHeight,
	fileType
) {
	return new Promise((resolve) => {
		const canvas = document.createElement("canvas");

		canvas.width = scale * initalWidth;
		canvas.height = scale * initalHeight;

		const ctx = canvas.getContext("2d");
		ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

		ctx.canvas.toBlob((blob) => {
			resolve(blob);
		}, fileType);
	});
}

export function replaceWord(text) {
	const search = `'`;
	const replaceWith = "`";
	return text.split(search).join(replaceWith);
}
export function convertTZ(date, tzString) {
	return new Date(
		(typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
			timeZone: tzString,
		})
	);
}


const getImageDimensionsNew = (image) => {
	return new Promise((resolve, reject) => {
		image.onload = function() {
			const width = this.width;
			const height = this.height;
			resolve({ width, height });
		};
		image.onerror = () => reject("Fail : Can't get image dimensions")
	})
}

const compressImgWithCanvas = ( image, scale, initalWidth, initalHeight, fileType ) => {
	return new Promise((resolve) => {
			const canvas = document.createElement("canvas");

			canvas.width = scale * initalWidth;
			canvas.height = scale * initalHeight;
	
			const ctx = canvas.getContext("2d");
			ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
			ctx.canvas.toBlob((blob) => {
				resolve(blob);
			}, fileType);
	})
}

const fromFileToBase64 = (file) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
			if (encoded.length % 4 > 0) {
				encoded += "=".repeat(4 - (encoded.length % 4));
			}
			resolve(encoded);
		};
		reader.onerror = () => reject('Fail : Cant convert image to base64');
	})
}

export const compressImgToBase64 = async (url, fileType, desiredWidth, desiredHeight ) => {

	return catchAsyncPromise(async() => {
		// get blob
		const result = await fetch(url)
		const blob = await result.blob()

		//create html image files
		const image = new Image()
		image.src = URL.createObjectURL(blob)

		const base64Result = async (blob, image) => {
			const base64 = await fromFileToBase64(blob)
			if(base64) URL.revokeObjectURL(image)
			return base64
		}
		
		// return if image already small
		const { width, height } = await getImageDimensionsNew(image)
		if(width < desiredWidth || height < desiredHeight) {
			return base64Result(blob, image)
		}
		
		const widthRatioBlob = await compressImgWithCanvas(image, desiredWidth / width, width, height, fileType)
		const heighRatioBlob = await compressImgWithCanvas(image, desiredHeight / height, width, height, fileType)
		//pick the smaller blob between both
		const selectedBlob = widthRatioBlob.size > heighRatioBlob.size ? heighRatioBlob : widthRatioBlob

		return base64Result(selectedBlob, image)
	})
} 


export default {
	formatDate,
	formatDob,
	getImageDimensions,
	compressImage,
	replaceWord,
	convertTZ,
	compressImgToBase64
};
