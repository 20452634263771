import axiosAri from "./utils/axiosAri";
import axiosAap from "./utils/axiosAap";
//import store from "../index";

/*
Method for user login at first time
*/
export async function login(email) {
	console.log("userVerification");
	localStorage.setItem("DoctorEmail", email);
	return await axiosAri({
		method: "post",
		url: "/DoctorLogin/LoginCheck",
		headers: { "content-type": "application/x-www-form-urlencoded" },
		data: new URLSearchParams({
			DOCTOR_EMAIL: localStorage.getItem("DoctorEmail"),
		}),
	});
}

/*
Method for verify code when user login
*/
export async function verifyCode(code) {
	return await axiosAap({
		method: "post",
		url: "/doctor/user-verification",
		headers: { "content-type": "application/x-www-form-urlencoded" },
		data: new URLSearchParams({
			Email: localStorage.getItem("DoctorEmail"),
			Code: code,
		}),
	});
}

/*
Method for update status online or offline
*/
export async function updateStatus(status) {
	return await axiosAri({
		method: "post",
		url: "/DoctorConsultation/DoctorUpdateStatus",
		headers: { "content-type": "application/x-www-form-urlencoded" },
		data: new URLSearchParams({
			DOCTOR_TOKEN: localStorage.getItem("DoctorToken"),
			DOCTOR_STATUS: status,
		}),
	});
}

/*
Logout and delete data on localStorage when user logout
*/
export function logout() {
	localStorage.removeItem("DoctorToken");
	localStorage.removeItem("DoctorName");
	localStorage.removeItem("DoctorCategory");
	localStorage.removeItem("DoctorPhoneNo");
	localStorage.removeItem("DoctorPhotoUrl");
	localStorage.removeItem("DoctorYear");
	localStorage.removeItem("DoctorId");
	localStorage.removeItem("TokenAap");
	localStorage.removeItem("DoctorIsOnline");
	localStorage.removeItem("DoctorTokenExp");
	localStorage.removeItem("DoctorLoginDate");
}

export default {
	login,
	verifyCode,
	logout,
	updateStatus,
};
