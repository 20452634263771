import {toastController} from '@ionic/vue'

//Init toast
export const openToast = async function openToast(msg) {
    const toast = await toastController
        .create({
        message: msg,
        duration: 3000,
        })
    return toast.present();
}

export const openToastSuccess = async function openToast(msg) {
    const toast = await toastController
        .create({
        message: msg,
        duration: 3000,
        color: "success"
        })
    return toast.present();
}

export const catchAsync = fn => fn().catch(msg => {
    openToast(msg)
})

export const catchAsyncPromise = fn => {
    return new Promise((resolve) => {
        resolve(fn())
    }).catch(openToast)
}

export const catchAsyncNotCatch = fn => {
    return new Promise((resolve) => {
        resolve(fn())
    })
}

export default {
    catchAsync,
    catchAsyncPromise,
    openToast,
    openToastSuccess,
    catchAsyncNotCatch
}
