<template>
	<IonApp>
		<IonSplitPane
			content-id="main-content"
			v-if="store.state.auth || loggedIn != null"
		>
			<ion-menu content-id="main-content" type="overlay">
				<ion-content>
					<ion-list id="inbox-list">
						<ion-grid>
							<ion-row>
								<ion-col size="12">
									<ion-avatar class="image-profile">
										<img
											v-if="d.doctorPhotoUrl != ''"
											:src="d.doctorPhotoUrl"
											alt="profile img"
										/>
										<img
											v-else
											src="../src/assets/images/avatar.svg"
											alt="profile img"
										/>
									</ion-avatar>
								</ion-col>
							</ion-row>
							<ion-row class="center-item">
								<ion-col size="12">
									<ion-note @click="goToProfile()" class="blue-ari doctor-name">
										{{ d.doctorName }}
									</ion-note>
								</ion-col>
							</ion-row>
							<ion-row class="center-item gap-email">
								<ion-col size="12">
									<ion-note class="email">{{ d.doctorEmail }}</ion-note>
								</ion-col>
							</ion-row>
						</ion-grid>

						<ion-menu-toggle auto-hide="false" lines="none">
							<ion-item
								@click="goToPage('/conversation')"
								lines="none"
								detail="false"
								class="hydrated"
								:class="{
									selected:
										pageRequestConsultation.includes(routeName) ||
										parentRoute === 'Conversation',
								}"
							>
								<ion-icon
									slot="start"
									:ios="createOutline"
									:md="createOutline"
								></ion-icon>
								<ion-label
									>Permintaan Konsultasi
									<span
										v-if="store.state.totalNewTicket > 0"
										class="redBadge"
										>{{ store.state.totalNewTicket }}</span
									>
								</ion-label>
							</ion-item>
							<ion-item
								@click="goToPage('/ticket-onprocess')"
								lines="none"
								detail="false"
								class="hydrated"
								:class="{
									selected:
										pageProcessConsultation.includes(routeName) ||
										parentRoute === 'TicketOnProcess',
								}"
							>
								<ion-icon
									slot="start"
									:ios="chatbubblesOutline"
									:md="chatbubblesOutline"
								></ion-icon>
								<ion-label>Konsultasi Dimulai</ion-label>
							</ion-item>
							<ion-item
								@click="goToPage('/ticket-complete')"
								lines="none"
								detail="false"
								class="hydrated"
								:class="{
									selected:
										pageCompleteConsultation.includes(routeName) ||
										parentRoute === 'TicketComplete',
								}"
							>
								<ion-icon
									slot="start"
									:ios="checkmarkCircleOutline"
									:md="checkmarkCircleOutline"
								></ion-icon>
								<ion-label>Konsultasi Selesai</ion-label>
							</ion-item>
							<ion-item
								@click="LogOut()"
								router-link="/login"
								lines="none"
								detail="false"
								class="hydrated"
								:class="{ selected: routeName === 'Login' }"
							>
								<ion-icon
									slot="start"
									:ios="logOutOutline"
									:md="logOutOutline"
								></ion-icon>
								<ion-label>Logout</ion-label>
							</ion-item>
						</ion-menu-toggle>
					</ion-list>
				</ion-content>
			</ion-menu>
			<ion-router-outlet id="main-content"></ion-router-outlet>
		</IonSplitPane>
		<div class="center" v-else>
			<router-view></router-view>
		</div>
		<ion-alert
			:is-open="expiredSession"
			header="Sesi Login Berakhir"
			message="Silahkan login ulang kembali."
			css-class="my-custom-class"
			:buttons="textButtonOK"
			@didDismiss="onOkButton"
			backdrop-dismiss="false"
		>
		</ion-alert>
	</IonApp>
</template>

<script>
import {
	IonApp,
	IonContent,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonMenu,
	IonMenuToggle,
	IonNote,
	IonRouterOutlet,
	IonSplitPane,
	useBackButton,
	IonAvatar,
	IonRow,
	IonCol,
	IonGrid,
	IonAlert,
	// onIonViewDidEnter,
} from "@ionic/vue";
import {
	defineComponent,
	ref,
	provide,
	reactive,
	computed,
	watchEffect,
} from "vue";
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
import { convertTZ } from "./store/methods/utils/utils";
import {
	chatboxOutline, //
	syncOutline, //
	checkmarkDoneCircleOutline, //
	logOutOutline,
	createOutline,
	chatbubblesOutline,
	checkmarkCircleOutline,
	personOutline,
} from "ionicons/icons";
import db from "@/fb";
import { listRequestConsultation } from "./store/methods/ticket";

export default defineComponent({
	name: "App",
	components: {
		IonApp,
		IonContent,
		IonIcon,
		IonItem,
		IonLabel,
		IonList,
		IonMenu,
		IonMenuToggle,
		IonNote,
		IonRouterOutlet,
		IonSplitPane,
		IonAvatar,
		IonRow,
		IonCol,
		IonGrid,
		IonAlert,
	},
	setup() {
		provide("store", store);
		const route = useRoute();
		const router = useRouter();
		var routeName = ref(null);
		var parentRoute = ref(null);
		//set listen new incoming ticket
		var unListenNewTicket = null;
		const entryticket = db.collection("entryticket").doc("status");

		const loggedIn = localStorage.getItem("DoctorToken");
		const d = reactive({
			doctorName: localStorage.getItem("DoctorName"),
			doctorPhotoUrl: localStorage.getItem("DoctorPhotoUrl"),
			doctorEmail: localStorage.getItem("DoctorEmail"),
		});
		// var selectedIndex = ref(0);
		const expiredSession = ref(null);
		const textButtonOK = ["OK"];
		const pageRequestConsultation = [
			"Conversation",
			"TicketInformationOnRequest",
		];
		const pageProcessConsultation = [
			"TicketOnProcess",
			"TicketOnProcessDetail",
			"ActiveChat",
			"EndChat",
		];
		const pageCompleteConsultation = [
			"TicketComplete",
			"TicketCompleteDetail",
			"ChatHistory",
		];

		//route page for un-authorized user
		const arrRoutes = ["/login", "/verify-code"];
		// const queryPage = ref(null)

		watchEffect(() => {
			/* check current Route */
			routeName = computed(() => route.name);
			parentRoute = computed(() => route.query.parentRoute);
		});

		function LogOut() {
			store.userVerification.logout();
			router.go();
			location.reload();
		}

		//checking user expired login by interval
		let interval = setInterval(() => {
			let loginDate = localStorage.getItem("DoctorLoginDate");
			let date = convertTZ(new Date(), "Asia/Jakarta")
				.getDate()
				.toString();
			if (!arrRoutes.includes(route.path)) {
				if (loggedIn != null && loginDate != null) {
					if (date != loginDate) {
						expiredSession.value = true;
						clearInterval(interval);
						//console.log("ya do joob");
					}
				}
			}
		}, 1000);
		function onOkButton() {
			store.userVerification.logout();
			setTimeout(() => {
				router.replace("/login");
				location.reload();
			}, 200);
		}

		//set hardware mobile back button
		useBackButton(10, () => {
			if (route.path == "/ticket-onprocess") {
				router.replace("/conversation");
			} else if (route.path == "/ticket-complete") {
				router.replace("/conversation");
			} else if (route.path == "/profile") {
				router.replace("/conversation");
			} else if (route.path == "/conversation") {
				router.replace("/conversation");
			} else if (route.path == "/ticket-onprocess-detail") {
				router.replace("/ticket-onprocess");
			} else {
				router.go(-1);
			}
		});
		function goToPage(page) {
			router.replace(page);
		}

		function goToProfile() {
			router.replace("/profile");
		}
		//set realtime notification for new incoming ticket
		let loginDate = localStorage.getItem("DoctorLoginDate");
		var audio = new Audio(require("./assets/audio/message_tone.mp3"));
		if (!arrRoutes.includes(route.path)) {
			if (loggedIn != null && loginDate != null) {
				//console.log("listen start");
				unListenNewTicket = entryticket.onSnapshot(async (docSnapshot) => {
					if (docSnapshot.data() != null) {
						//console.log("yes trigered");
						if (store.state.listenIncomingTicket) {
							const isNewTicketExist = await listRequestConsultation();
							if (isNewTicketExist != null) {
								if (store.state.totalNewTicket > 0) {
									audio.play();
								}
							}
						} else {
							store.state.listenIncomingTicket = true;
							listRequestConsultation();
						}
						// store.ticket.listRequestConsultation();
						//play audio when incoming new ticket triggered
					}
				});
			} else {
				unListenNewTicket();
				// console.log("unlisted middle");
			}
		}

		return {
			store,
			pageRequestConsultation,
			pageProcessConsultation,
			pageCompleteConsultation,
			isSelected: (url) => (url === route.path ? "selected" : ""),
			LogOut,
			chatboxOutline,
			syncOutline,
			checkmarkDoneCircleOutline,
			loggedIn,
			logOutOutline,
			d,
			goToProfile,
			createOutline,
			chatbubblesOutline,
			checkmarkCircleOutline,
			personOutline,
			expiredSession,
			onOkButton,
			textButtonOK,
			routeName,
			parentRoute,
			goToPage,
		};
	},
});
</script>

<style scoped>
ion-menu ion-content {
	--background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
	--padding-start: 8px;
	--padding-end: 8px;
	--padding-top: 20px;
	--padding-bottom: 20px;
}

ion-menu.md ion-list {
	padding: 20px 0;
}

ion-menu.md ion-note {
	margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
	padding-left: 10px;
}

/* ion-menu.md ion-list#inbox-list {
	border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
} */

ion-menu.md ion-list#inbox-list ion-list-header {
	font-size: 22px;
	font-weight: 600;

	min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
	font-size: 16px;

	margin-bottom: 18px;

	color: #757575;

	min-height: 26px;
}

ion-menu.md ion-item {
	--padding-start: 10px;
	--padding-end: 10px;
	border-radius: 4px;
	cursor: pointer;
}

ion-menu.md ion-item.selected {
	--background: rgba(var(--ion-color-arisebelas-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
	color: var(--ion-color-aridelapan);
}

ion-menu.md ion-item ion-icon {
	color: #616e7e;
}

ion-menu.md ion-item ion-label {
	font-weight: 500;
}

ion-menu.ios ion-content {
	--padding-bottom: 20px;
}

ion-menu.ios ion-list {
	padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
	line-height: 24px;
	margin-bottom: 20px;
}

ion-menu.ios ion-item {
	--padding-start: 16px;
	--padding-end: 16px;
	--min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
	color: var(--ion-color-aridelapan);
}

ion-menu.ios ion-item ion-icon {
	font-size: 24px;
	color: #73849a;
}

ion-menu.ios ion-list#labels-list ion-list-header {
	margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
	padding-left: 16px;
	padding-right: 16px;
}

ion-menu.ios ion-note {
	margin-bottom: 8px;
}

ion-note {
	display: inline-block;
	font-size: 16px;

	color: var(--ion-color-medium-shade);
}

ion-item.selected {
	--color: var(--ion-color-aridelapan);
}
.email {
	color: #000000;
	margin: 0 auto;
	font-size: 14px;
}
.gap-email {
	margin-top: -35px;
}
.image-profile {
	margin: 0 auto;
}
.doctor-name {
	cursor: pointer;
	font-weight: bold;
	font-size: 20px;
}
.center-item {
	text-align: center;
	align-items: center;
	align-content: center;
}
.redBadge {
	border-radius: 50%;
	width: 20px;
	height: 20px;
	padding-top: 2px;
	display: inline-block;
	background: rgb(236, 48, 48);
	text-align: center;
	color: white;
	position: relative;
	z-index: 5000;
	font: 14px;
	cursor: pointer;
}
</style>
