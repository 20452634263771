import axiosAri from "./utils/axiosAri";
import axiosAap from "./utils/axiosAap";
import store from "../index";

export async function listRequestConsultation() {
	// console.log("listRequestConsultation");
	try {
		store.state.isLoading = true;
		const r = await axiosAri({
			method: "post",
			url: "/DoctorConsultation/ConsultationLoadListData",
			headers: { "content-type": "application/x-www-form-urlencoded" },
			data: new URLSearchParams({
				DOCTOR_TOKEN: localStorage.getItem("DoctorToken"),
				TICKET_STATUS_ID: 1,
			}),
		});
		//console.log("r", r);
		if (
			r.status === 200 &&
			r.data.ResponseCode == "000" &&
			r.data.ConsultationListDataReponseModel != null
		) {
			//console.log(r.data);
			store.state.isLoading = false;
			//store.state.ticket.request = null;
			store.state.ticket.request = r.data.ConsultationListDataReponseModel;
			store.state.ticket.buttonName = "Mulai Konsultasi";

			store.state.totalNewTicket = parseInt(
				r.data.ConsultationListDataReponseModel.length
			);
			if (r.data.ConsultationListDataReponseModel.length > 0) {
				return r.data.ConsultationListDataReponseModel.length;
			}
		}
		if (r.status === 200 && r.data.ResponseCode == "100") {
			store.state.isLoading = false;
			store.state.ticket.request = null;
			return null;
		}
	} catch (error) {
		store.state.isLoading = false;
		console.log("error:", error);
		return null;
	}
	store.state.isLoading = false;
}
export async function refreshListRequestConsultation() {
	// console.log("refreshListRequestConsultation");
	try {
		const r = await axiosAri({
			method: "post",
			url: "/DoctorConsultation/ConsultationLoadListData",
			headers: { "content-type": "application/x-www-form-urlencoded" },
			data: new URLSearchParams({
				DOCTOR_TOKEN: localStorage.getItem("DoctorToken"),
				TICKET_STATUS_ID: 1,
			}),
		});
		if (
			r.status === 200 &&
			r.data.ResponseCode == "000" &&
			r.data.ConsultationListDataReponseModel != null
		) {
			//store.state.ticket.request = null;
			store.state.ticket.request = r.data.ConsultationListDataReponseModel;
		}
		if (r.status === 200 && r.data.ResponseCode == "100") {
			store.state.ticket.request = null;
		}
	} catch (error) {
		console.log("error:", error);
	}
}

export async function listStartConsultation() {
	// console.log("listStartConsultation");
	store.state.isLoading = true;
	try {
		const r = await axiosAri({
			method: "post",
			url: "/DoctorConsultation/ConsultationLoadListData",
			headers: { "content-type": "application/x-www-form-urlencoded" },
			data: new URLSearchParams({
				DOCTOR_TOKEN: localStorage.getItem("DoctorToken"),
				TICKET_STATUS_ID: 2,
			}),
		});
		//console.log("r", r.data);
		if (
			r.status === 200 &&
			r.data.ResponseCode == "000" &&
			r.data.ConsultationListDataReponseModel != null
		) {
			store.state.isLoading = false;
			//store.state.ticket.start = null;
			store.state.ticket.start = r.data.ConsultationListDataReponseModel;
			store.state.ticket.buttonName = "Lanjut Konsultasi";
		}
		if (r.status === 200 && r.data.ResponseCode == "100") {
			store.state.isLoading = false;
			store.state.ticket.start = null;
		}
	} catch (error) {
		store.state.isLoading = false;
		console.log("error:", error);
	}
	store.state.isLoading = false;
}

export async function doctorEndConsultation(
	ticketNo,
	followUpId,
	doctorNote,
	doctorNoteForNurse
) {
	//console.log("doctorEndConsultation");
	return await axiosAap({
		method: "post",
		url: "/doctor/end-chat",
		headers: {
			Authorization: `Bearer ${localStorage.getItem("TokenAap")}`,
		},
		data: {
			TicketNo: ticketNo,
			UserId: localStorage.getItem("DoctorId"),
			TicketFollowUpId: followUpId,
			DoctorToken: localStorage.getItem("DoctorToken"),
			TicketDoctorNote: doctorNote || "",
			TicketDoctorNoteForNurse: doctorNoteForNurse || "",
		},
	});
}

export async function listTicketComplete() {
	// console.log("listTicketComplete");
	store.state.isLoading = true;
	try {
		const r = await axiosAri({
			method: "post",
			url: "/DoctorConsultation/ConsultationLoadListData",
			headers: { "content-type": "application/x-www-form-urlencoded" },
			data: new URLSearchParams({
				DOCTOR_TOKEN: localStorage.getItem("DoctorToken"),
				TICKET_STATUS_ID: 3,
			}),
		});
		//console.log("R COMPLETE", r);
		if (
			r.status === 200 &&
			r.data.ResponseCode == "000" &&
			r.data.ConsultationListDataReponseModel != null
		) {
			store.state.isLoading = false;
			//store.state.ticket.complete = null;
			store.state.ticket.complete = r.data.ConsultationListDataReponseModel;
		}
		if (r.status === 200 && r.data.ResponseCode == "100") {
			store.state.isLoading = false;
			store.state.ticket.complete = null;
		}
	} catch (error) {
		store.state.isLoading = false;
		console.log("error:", error);
	}
	store.state.isLoading = false;
}

export async function ticketInformation(ticketNo) {
	// console.log("ticketInformation");
	return await axiosAri({
		method: "post",
		url: "/DoctorConsultation/ConsultationLoadListDataDetail",
		headers: { "content-type": "application/x-www-form-urlencoded" },
		data: new URLSearchParams({
			DOCTOR_TOKEN: localStorage.getItem("DoctorToken"),
			TICKET_NO: ticketNo,
		}),
	});
}

export async function doctorStartConsultation(ticketNo) {
	return await axiosAri({
		method: "post",
		url: "/DoctorConsultation/DoctorStartConsultation",
		headers: { "content-type": "application/x-www-form-urlencoded" },
		data: new URLSearchParams({
			DOCTOR_TOKEN: localStorage.getItem("DoctorToken"),
			TICKET_NO: ticketNo,
		}),
	});
}
export async function doctorLoadFollowupIdData() {
	// console.log("doctorLoadFollowupIdData");
	try {
		let r = await axiosAri({
			method: "post",
			url: "/DoctorConsultation/ConsultationLoadFollowupIdData",
			headers: { "content-type": "application/x-www-form-urlencoded" },
			data: new URLSearchParams({
				DOCTOR_TOKEN: localStorage.getItem("DoctorToken"),
			}),
		});
		if (r != null && r.data.ResponseCode == "000") {
			return r.data;
		}
		if (r != null && r.data.ResponseCode != "000") {
			return null;
		}
	} catch (err) {
		return null;
	}
}

export default {
	listRequestConsultation,
	listStartConsultation,
	listTicketComplete,
	ticketInformation,
	doctorStartConsultation,
	doctorEndConsultation,
	refreshListRequestConsultation,
	doctorLoadFollowupIdData,
};
